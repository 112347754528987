.index-footer {
  height: 25px;
  margin-top: 25px;
  width: 100%;
  text-align: center;
}

.grey-border {
  border-top: 1px solid #eee;
}

.footer-links {
  width: 70%;
  height: 25px;
  margin: 0px auto;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
}

.footer-links .link {
  width: 19.8%;
  text-align: center;
}

.address {
  width: 100%;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 25px;
}

@media (max-width: 780px) {
  .footer-links {
    display: table;
  }
}
